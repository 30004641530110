@media (min-width: 769px) {

    @keyframes flow-lr {

        0%,
        100% {
            transform: translateX(-10px);
        }

        50% {
            transform: translateX(10px);
        }
    }

    .sb-card-list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
    }

    .card-element {
        user-select: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        background: #121212;
        max-width: 350px;
        padding: 20px 20px 45px 20px;
        cursor: pointer;
        transition: all .2s ease;
        min-height: 440px;
        min-width: 350px;




        .ce-msg-right {
            margin-left: auto;

            background: var(--col2);

        }

        &:hover {
            transform: scale(1.02)
        }

        .ce-dotted {
            font-size: 35px;
            color: var(--col);
            letter-spacing: -2px;
        }



        .ce-title-arrow-alt {
            flex-wrap: wrap;
            width: 100%;
            margin: 0 auto;
        }

        .ce-description {
            font-size: 14px;
            font-weight: 200;
            opacity: .5;
            line-height: 0.2;
        }

        .ce-img {
            margin: 20px 0;
            align-self: center;
            min-height: 150px;
            max-height: 200px;
        }
    }

    .ce-msg {
        min-height: 15px;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 10px;
        letter-spacing: 1px;
        background: var(--col);
        padding: 5px 10px;
    }

    .ce-title-arrow {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .ce-title {
            white-space: pre-line;
            word-wrap: break-word;
            font-size: 25px;
            font-weight: 800;
        }

        .ce-arrow {
            animation: flow-lr 5s ease-in-out infinite;
            max-width: 100px;
        }
    }

    .ce-description-steps {

        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: space-between;
        align-items: start;
        width: 100%;
        margin-top: 30px;
        margin-bottom: auto;
        gap: 10px;

    }


    .cds-line {
        margin: 0 auto;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cds-text {
            font-weight: 500;
            font-size: 15px;
            max-width: 230px;
        }

        .cds-number {
            color: var(--col2);
            margin: 0;
            line-height: 100%;
            font-size: 40px;
            font-weight: 1000;
        }
    }


    .card-element-left-side {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
        transition: all .2s ease;

        .ce-msg {
            text-transform: uppercase;
            font-weight: 800;
            font-size: 10px;
            letter-spacing: 1px;
            background: var(--col);
            padding: 5px 10px;
        }

        .ce-dotted {
            font-size: 35px;
            color: var(--col);
            letter-spacing: -2px;
            line-height: 0.2;

            margin: 0;
        }


        .ce-title {
            white-space: pre-line;
            word-wrap: break-word;
            font-size: 40px;
            font-weight: 800;
            max-width: 320px;
            line-height: 1;
        }

        .ce-arrow {
            animation: flow-lr 5s ease-in-out infinite;
            max-width: 100px;
        }


        .ce-description {
            font-size: 14px;
            font-weight: 200;
            opacity: .5;
            line-height: 0.3;
        }

    }

    .card-element-big {

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px 20px 35px 20px;

        transition: all .2s ease;
        background: #121212;
        width: 100%;


        .ce-img {
            position: relative;
            margin: 20px 0;
            align-self: center;
            min-height: 150px;
            /* max-height: 200px; */
            margin: 0 auto;


            &::before {
                content: '2';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%);
                width: 200px;
                height: 200px;
                background: #868686;
                z-index: 90;
            }
        }
    }

    .ce-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: var(--col);
        cursor: pointer;
        transition: all .2s ease;
        font-weight: 700;
        font-size: 12px;
        border-radius: 5px;

        .ce-btn-img {
            transition: all .2s ease;
        }

        &:hover {
            transform: scale(1.05);
            background: white;
            color: black;

            .ce-btn-img {
                filter: invert();
            }
        }
    }

    .ce-btn-alt {
        background: var(--col2);

    }

    .card-element-alt {
        min-width: 350px;
        background: var(--col);
    }

    .sb-title {
        font-size: 40px;
        font-weight: 800;
        margin-bottom: 50px;
        text-transform: uppercase;
        letter-spacing: 2px;
    }
}

@media (max-width: 768px) {


    @keyframes flow-lr {

        0%,
        100% {
            transform: translateX(-10px);
        }

        50% {
            transform: translateX(10px);
        }
    }

    .sb-card-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* flex-wrap: wrap; */
        /* flex-direction: row; */
        justify-content: space-between;
        gap: 10px;
        box-sizing: border-box;
    }

    .card-element {
        user-select: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        background: #121212;
        max-width: 100%;
        padding: 20px;
        cursor: pointer;
        transition: all .2s ease;
        min-height: 440px;
        min-width: 350px;


        .ce-msg-right {
            margin-left: auto;

            background: var(--col2);

        }

        &:hover {
            transform: scale(1.02)
        }

        .ce-dotted {
            font-size: 35px;
            color: var(--col);
            letter-spacing: -2px;
        }



        .ce-title-arrow-alt {
            flex-wrap: wrap;
            width: 100%;
            margin: 0 auto;
        }

        .ce-description {
            font-size: 14px;
            font-weight: 200;
            opacity: .5;
            line-height: 0.2;
        }

        .ce-img {
            margin: 20px 0;
            align-self: center;
            min-height: 150px;
            max-height: 200px;
        }
    }

    .ce-msg {
        min-height: 15px;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 10px;
        letter-spacing: 1px;
        background: var(--col);
        padding: 5px 10px;
    }

    .ce-title-arrow {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .ce-title {
            white-space: pre-line;
            word-wrap: break-word;
            font-size: 25px;
            font-weight: 800;
        }

        .ce-arrow {
            animation: flow-lr 5s ease-in-out infinite;
            max-width: 100px;
        }
    }

    .ce-description-steps {

        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: space-between;
        align-items: start;
        width: 100%;
        margin-top: 30px;
        margin-bottom: auto;
        gap: 10px;

    }


    .cds-line {
        margin: 0 auto;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cds-text {
            font-weight: 500;
            font-size: 15px;
            max-width: 230px;
        }

        .cds-number {
            color: var(--col2);
            margin: 0;
            line-height: 100%;
            font-size: 40px;
            font-weight: 1000;
        }
    }


    .card-element-left-side {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
        transition: all .2s ease;

        .ce-msg {
            text-transform: uppercase;
            font-weight: 800;
            font-size: 10px;
            letter-spacing: 1px;
            background: var(--col);
            padding: 5px 10px;
        }

        .ce-dotted {
            font-size: 35px;
            color: var(--col);
            letter-spacing: -2px;
            line-height: 0.2;

            margin: 0;
        }


        .ce-title {
            white-space: pre-line;
            word-wrap: break-word;
            font-size: 40px;
            font-weight: 800;
            max-width: 320px;
            line-height: 1;
        }

        .ce-arrow {
            animation: flow-lr 5s ease-in-out infinite;
            max-width: 100px;
        }


        .ce-description {
            font-size: 14px;
            font-weight: 200;
            opacity: .5;
            line-height: 0.3;
        }

    }

    .card-element-big {

        display: none;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px 20px 35px 20px;

        transition: all .2s ease;
        background: #121212;
        width: 100%;


        .ce-img {
            position: relative;
            margin: 20px 0;
            align-self: center;
            min-height: 150px;
            /* max-height: 200px; */
            margin: 0 auto;


            &::before {
                content: '2';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%);
                width: 200px;
                height: 200px;
                background: #868686;
                z-index: 90;
            }
        }
    }

    .ce-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: var(--col);
        cursor: pointer;
        transition: all .2s ease;
        font-weight: 700;
        font-size: 12px;
        border-radius: 5px;

        .ce-btn-img {
            transition: all .2s ease;
        }

        &:hover {
            transform: scale(1.05);
            background: white;
            color: black;

            .ce-btn-img {
                filter: invert();
            }
        }
    }

    .ce-btn-alt {
        background: var(--col2);

    }

    .card-element-alt {
        min-width: 350px;
        background: var(--col);
        box-sizing: border-box;
        padding: 20px;
    }

    .sb-title {
        font-size: 40px;
        font-weight: 800;
        margin-bottom: 50px;
        text-transform: uppercase;
        letter-spacing: 2px;
    }
}