@media (min-width: 769px) {

    .title_block {
        margin-top: 50px;
    }

    .tb-header {
        display: flex;
        flex-direction: row;
    }

    .tb-left {
        display: flex;
        flex-direction: column;
    }

    .tb-right {
        position: relative;
    }

    .slogan {
        font-weight: 500;
        letter-spacing: 2.5px;
        font-size: 15px;
        opacity: 0.3;
        margin-bottom: 25px;
        margin-top: 100px;
    }

    .big-logo {
        width: 600px;
        margin-bottom: 20px;
    }

    .title-block-description {
        margin-bottom: 20px;
    }

    .second-description {
        margin-bottom: 30px;
        font-size: 15px;
    }

    .big-phone {
        max-width: 700px;
        position: absolute;
        top: 100px;
        left: -100px;
    }

    @keyframes arrowAnimation {

        0%,
        100% {
            transform: rotate(0deg);
        }

        50% {
            transform: rotate(-10deg);
        }
    }

    .arrowAnim {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 100px;
            height: 150px;
            left: 300px;
            top: -70px;
            animation: arrowAnimation 5s ease-in-out infinite;
            background-image: url('../images/title_block_imgs/array-circle.dfd72215.png');
        }
    }

    .tb-bottom {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .tb-b-elem {
        display: flex;
        flex-direction: column;
        max-width: 330px;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: all .2s ease;
        cursor: pointer;

        &:hover {

            .tb-b-elem-child {
                transform: scale(1.1);
            }

            .tb-b-elem-description {
                transform: translateY(10px);
            }
        }

        .tb-b-elem-img {
            max-width: 80px;
            transition: all .2s ease;
        }

        .tb-b-elem-label {
            transition: all .2s ease;
            text-transform: uppercase;
            font-weight: 700;
            margin: 5px 5px 7px;
        }

        .tb-b-elem-description {
            transition: all .2s ease;
            opacity: 0.5;
            font-size: 13px;
            font-weight: 200;
        }
    }

    @keyframes arrow-up-down {

        0%,
        100% {
            transform: translateY(-5px)
        }

        50% {
            transform: translateY(5px)
        }
    }

    .arrowBlock {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .arrowAnim2 {
        max-width: 100%;

        transition: all .2s ease;
        animation: arrow-up-down 3s infinite;
    }

    .tb-video {
        position: absolute;
        top: 20px;
        /* transform: translateY(-50%); */
        left: -200px;
        z-index: -9999;
        /* width: 700px; */
    }
}

@media (max-width: 768px) {


    .title_block {
        margin-top: 50px;
    }

    .tb-header {
        display: flex;
        flex-direction: column;
    }

    .tb-left {
        display: flex;
        flex-direction: column;
    }

    .tb-right {
        /* position: relative; */
    }

    .slogan {
        font-weight: 500;
        letter-spacing: 2.5px;
        font-size: 15px;
        opacity: 0.3;
        margin-bottom: 25px;
        margin-top: 100px;
    }

    .big-logo {
        width: 300px;
        margin-bottom: 20px;
    }

    .title-block-description {
        margin-bottom: 20px;
    }

    .second-description {
        margin-bottom: 30px;
        font-size: 15px;
    }

    .big-phone {
        max-width: 700px;
        position: absolute;
        top: 100px;
        left: -100px;
    }

    @keyframes arrowAnimation {

        0%,
        100% {
            transform: rotate(0deg);
        }

        50% {
            transform: rotate(-10deg);
        }
    }

    .arrowAnim {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 100px;
            height: 150px;
            left: 300px;
            top: -70px;
            animation: arrowAnimation 5s ease-in-out infinite;
            background-image: url('../images/title_block_imgs/array-circle.dfd72215.png');
        }
    }

    .tb-bottom {
        display: flex;
        align-items: start;
        justify-content: space-around;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .tb-b-elem {
        display: flex;
        flex-direction: column;
        max-width: 130px;
        /* min-width: ; */
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: all .2s ease;
        cursor: pointer;

        &:hover {

            .tb-b-elem-child {
                transform: scale(1.1);
            }

            .tb-b-elem-description {
                transform: translateY(10px);
            }
        }

        .tb-b-elem-img {
            width: ;
            min-height: 100px;
            width: auto;
            box-sizing: content-box;
            transition: all .2s ease;
        }

        .tb-b-elem-label {
            transition: all .2s ease;
            text-transform: uppercase;
            font-weight: 700;
            margin: 5px 5px 7px;
        }

        .tb-b-elem-description {
            transition: all .2s ease;
            opacity: 0.5;
            font-size: 13px;
            font-weight: 200;
        }
    }

    @keyframes arrow-up-down {

        0%,
        100% {
            transform: translateY(-5px)
        }

        50% {
            transform: translateY(5px)
        }
    }

    .arrowBlock {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .arrowAnim2 {
        max-width: 100%;
        transition: all .2s ease;
        animation: arrow-up-down 3s infinite;
    }

    .tb-video {
        position: absolute;
        max-width: 400px;
        user-select: none;
        pointer-events: none;
        top: 0px;
        /* opacity: .5; */
        left: 50%;
        transform: translate(-50%);
        z-index: -9999;
        -webkit-mask-image: linear-gradient(black 0%, transparent 70%);
        mask-image: linear-gradient(black 0%, transparent 70%);
    }
}