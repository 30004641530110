@keyframes pulse-header {

    0%,
    100% {
        box-shadow: 0 0 55px rgba(226, 72, 52, 0.4);
    }

    50% {
        box-shadow: 0 0 15px rgba(226, 72, 52, 0.4);
    }
}

@keyframes kacheli {

    0%,
    100% {
        transform: translateX(-20px) rotate(-5deg);
    }

    50% {
        transform: translateX(20px) rotate(5deg);
    }
}

@keyframes kacheliMobile {

    0%,
    100% {
        transform: translateX(-10px) rotate(-7deg);
    }

    50% {
        transform: translateX(10px) rotate(7deg);
    }
}

@media (min-width: 769px) {

    .header {

        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        min-width: 1200px;
        background: hsla(0, 0%, 10%, 0.85);
        border-radius: 10px;
        border: 1px solid rgba(226, 72, 52, 0.5);
        z-index: 9999;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        padding: 0px 20px;
        box-shadow: 0 0 25px rgba(226, 72, 52, 0.4);
        animation: pulse-header 5s infinite;

    }

    .header-logo {
        transition: all .3s ease;
        cursor: pointer;
        width: 100px;
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;


        &:hover {
            transform: scale(1.2);
        }

        .header-logo-img {
            position: absolute;
            width: 150px;
            animation: kacheli 5s infinite ease-in-out;
        }
    }



    .nav-btn {
        transition: all .2s ease;
        background: none;
        color: white;
        font-size: 12px;
        border: none;
        font-weight: 500;
        cursor: pointer;

        margin-left: 10px;

        &:hover {
            color: var(--col)
        }
    }

    .header-phone {
        display: flex;
        align-items: center;
    }

    .header-phone-cbc_phone {
        transition: all .2s ease;
        font-weight: 700;
        letter-spacing: 0.5px;
        font-size: 15px;
        cursor: pointer;

        &:hover {
            color: var(--col)
        }
    }

    .header-phone-cbc_icon {
        transition: all .2s ease;
        cursor: pointer;

        &:hover {
            transform: scale(1.2);
        }
    }

}


@media (max-width: 768px) {

    .header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 400px;
        gap: 100px;
        margin: 0 auto;
        /* width: 100%; */
        /* border: red 1px solid; */
        /* left: 50%; */
        /* transform: translateX(-50%); */
        /* min-width: 1200px; */
        /* background: hsla(0, 0%, 10%, 0.85); */
        /* border-radius: 10px; */
        /* border: 1px solid rgba(226, 72, 52, 0.5); */
        z-index: 9999;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        padding: 0px 20px;
        /* box-shadow: 0 0 25px rgba(226, 72, 52, 0.4); */
        /* animation: pulse-header 5s infinite; */


        .header-phone,
        .header-social,
        .header-callback {
            display: none;
        }

    }

    .header-navmenu {
        display: none;
    }


    .header-logo {
        transition: all .3s ease;
        cursor: pointer;
        width: 100px;
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;


        &:hover {
            transform: scale(1.2);
        }

        .header-logo-img {
            position: absolute;
            width: 150px;
            animation: kacheliMobile 5s infinite ease-in-out;
        }
    }



    .nav-btn {
        transition: all .2s ease;
        background: none;
        color: white;
        font-size: 12px;
        border: none;
        font-weight: 500;
        cursor: pointer;

        margin-left: 10px;

        &:hover {
            color: var(--col)
        }
    }

    .header-phone {
        display: flex;
        align-items: center;
    }

    .header-phone-cbc_phone {
        transition: all .2s ease;
        font-weight: 600;
        letter-spacing: 2px;
        font-size: 18px;
        cursor: pointer;

        &:hover {
            color: var(--col)
        }
    }

    .header-phone-cbc_icon {
        transition: all .2s ease;
        cursor: pointer;

        &:hover {
            transform: scale(1.2);
        }
    }

}

.pe-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
    gap: 10px;
}

.pay-elem {
    display: flex;
    max-width: 100px;
    max-height: 40px;
    height: 100%;
}

.union {
    max-width: 40px;
}

.sber {
    max-width: 25px;
}

.sbp {
    max-width: 30px;
}