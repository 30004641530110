@media (min-width: 769px) {
    .modal-content {
        position: absolute;
        /* min-width: 500px; */
        /* min-height: 500px; */
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        background: var(--bcol);
        padding: 50px;
        z-index: 1000;
        outline: none;
        overflow: hidden;
        background-image: url(/public/imgs/images/notes.png);
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;

        &::after {
            content: "";
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%, -50%);
            width: 200px;
            height: 120px;
            z-index: -9990;
            background-image: url(/public/imgs/images/lines.png);
            background-repeat: no-repeat;
        }

        &::before {
            content: "";
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 50%);
            width: 200px;
            height: 120px;
            z-index: -9990;
            background-image: url(/public/imgs/images/lines.png);
            background-repeat: no-repeat;
        }
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.75);
        z-index: 999;
    }

    .close-button {
        line-height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        user-select: none;
        position: absolute;
        width: 30px;
        height: 30px;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 20px;
        cursor: pointer;
        color: var(--col);
        transition: all .2s ease;

        &:hover {
            color: white;
            transform: rotate(180deg);
        }
    }

    .fb-err-msg {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        margin-top: 15px;
        background-color: var(--col);
        padding: 3px 10px;
        box-shadow: 0 0 15px var(--col);
    }
}



@media (max-width: 768px) {
    .modal-content {
        position: absolute;
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        background: var(--bcol);
        padding: 50px;
        z-index: 1000;
        outline: none;
        overflow: hidden;
        background-image: url(/public/imgs/images/notes.png);
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;

        &::after {
            content: "";
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%, -50%);
            width: 200px;
            height: 120px;
            z-index: -9990;
            background-image: url(/public/imgs/images/lines.png);
            background-repeat: no-repeat;
        }

        &::before {
            content: "";
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 50%);
            width: 200px;
            height: 120px;
            z-index: -9990;
            background-image: url(/public/imgs/images/lines.png);
            background-repeat: no-repeat;
        }
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.75);
        z-index: 999;
    }

    .close-button {
        line-height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        user-select: none;
        position: absolute;
        width: 30px;
        height: 30px;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 20px;
        cursor: pointer;
        color: var(--col);
        transition: all .2s ease;

        &:hover {
            color: white;
            transform: rotate(180deg);
        }
    }

    .fb-err-msg {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        margin-top: 15px;
        background-color: var(--col);
        padding: 3px 10px;
        box-shadow: 0 0 15px var(--col);
    }
}