/* Общие стили для контейнера */
@media (min-width: 769px) {


    .container {
        display: flex;
    }

    .left-panel {
        padding: 20px;
        position: fixed;
        /* Фиксированное положение на экране */
        top: 50%;
        /* Центрирование по вертикали */
        transform: translateY(-50%);
        height: auto;
        overflow-y: auto;
    }

    .left-panel ul {
        list-style-type: none;
        padding: 0;
    }

    .left-panel li {
        cursor: pointer;
        font-weight: bold;
        font-size: 20px;
    }

    .left-panel li:hover {
        color: var(--col);
    }

    .right-panel {
        padding: 20px;
        margin-left: 50px;
    }

    .article {
        padding: 20px;
        border-radius: 15px;
        margin-bottom: 50px;




        h3 {
            /* width: 100%; */
            display: inline;
            position: relative;
            color: var(--col);
            font-weight: 800;
            text-wrap: wrap;
            font-size: 35px;
            cursor: pointer;
            transition: all .2s ease;

            span {
                transition: all .2s ease;
            }

            .copy-link {
                transition: all .2s ease;
                opacity: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -30px;
                font-size: 25px;
                font-weight: 800;
                z-index: 999;
            }


            .copied {
                transition: all .2s ease;
                display: inline-block;
                width: 173px;
                opacity: 1;
                left: calc(100% + 5px);
                font-size: 15px;
                border-radius: 10px;
                padding: 5px 10px;
                background-color: var(--bcol);
                border: 1px solid var(--col);
                font-weight: 500;
                text-wrap: nowrap;
            }

            &:hover {
                .copy-link {
                    opacity: 1;
                }
            }
        }

        p {
            word-spacing: 1px;
            font-weight: 500;
        }

        h3,
        p {
            margin: 0;

        }
    }

    .odd {
        h3 {
            color: var(--bcol);

            .copied {
                background-color: var(--col);
                border: 1px solid var(--bcol);
            }
        }

        background: var(--col);
    }

    .notOdd {

        background: var(--bcol);
    }

    ul {

        li {
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 100%;
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
            padding: 10px;
        }

        li.active {
            background-color: var(--col);
            border-radius: 10px;

            &:hover {
                color: var(--bcol);
            }
        }
    }

}

/* Общие стили для контейнера */
@media (max-width: 768px) {

    div.tit-des {
        margin-left: 65px !important;
    }

    .tit-des {
        text-align: left !important;
        margin-left: 65px !important;
    }

    .container {
        display: flex;
        /* width: 418px; */
    }

    .left-panel {
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        height: auto;
        overflow-y: auto;
    }

    .left-panel ul {
        list-style-type: none;
        padding: 0;
    }

    .left-panel li {
        cursor: pointer;
        font-weight: bold;
        font-size: 20px;
    }

    /* .left-panel li:hover {
        color: var(--col);
    } */

    .right-panel {
        padding: 20px;
        margin-left: 50px;
        max-width: 80%;
    }

    .article {
        padding: 20px;
        border-radius: 15px;
        margin-bottom: 50px;




        h3 {
            /* width: 100%; */
            display: inline;
            position: relative;
            color: var(--col);
            font-weight: 800;
            text-wrap: wrap;
            font-size: 25px;
            cursor: pointer;
            transition: all .2s ease;

            span {
                transition: all .2s ease;
            }

            .copy-link {
                transition: all .2s ease;
                opacity: 1;
                position: absolute;
                
                top: 50%;
                transform: translateY(-50%);
                right: -30px;
                ;
                font-size: 25px;
                font-weight: 800;
                z-index: 999;
            }


            .copied {
                transition: all .2s ease;
                display: inline-block;
                width: 173px;
                opacity: 1;
                left: calc(100% + 5px);
                font-size: 15px;
                border-radius: 10px;
                padding: 5px 10px;
                background-color: var(--bcol);
                border: 1px solid var(--col);
                font-weight: 500;
                text-wrap: nowrap;
            }

            &:hover {
                .copy-link {
                    opacity: 1;
                }
            }
        }

        p {
            word-spacing: 1px;
            font-weight: 500;
        }

        h3,
        p {
            margin: 0;

        }
    }

    .odd {
        h3 {
            color: var(--bcol);

            .copied {
                background-color: var(--col);
                border: 1px solid var(--bcol);
            }
        }

        background: var(--col);
    }

    .notOdd {

        background: var(--bcol);
    }

    ul {

        li {
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 100%;
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
            padding: 10px;
        }

        li.active {
            background-color: var(--col);
            border-radius: 10px;

            &:hover {
                color: var(--bcol);
            }
        }
    }

}