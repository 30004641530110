.home-page.faq {
    min-height: 750px;
}

@keyframes txtShad {

    0%,
    100% {
        text-shadow: 0 0 35px rgba(255, 255, 255, 0.308);
    }

    50% {
        text-shadow: 0 0 15px rgba(255, 255, 255, 0.308);
    }

}

.faq-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;

    h1 {
        text-shadow: 0 0 35px rgba(255, 255, 255, 0.308);
        animation: infinite txtShad 5s ease;
    }
}

.page-title {
    font-size: 100px;
    font-weight: 800;
    margin: 0 auto;
    margin-top: 100px;
}

.page-description {
    display: flex;
    text-align: center;
    margin: 0 auto;
    opacity: .3;
    max-width: 720px;
}

.guide-list {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    max-width: 100%;
}

.guide-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 10px;
    padding: 20px;
    max-width: 350px;
    border-left: 2px solid var(--col);
    background-color: var(--bcol);
    user-select: none;
    transition: all .2s ease;

    &:hover {
        transform: rotate(-3deg) translateX(5px);

        a {
            transform: rotate(3deg) translateX(-5px);
        }
    }

    .inactive {

        &:hover {
            transform: none;
        }

        user-select: none;
        pointer-events: none;
    }

    h2,
    p,
    div.linkA {
        transition: all .2s ease;
        margin: 0;
    }

    h2 {
        transition: all .2s ease;

        margin-bottom: 10px;
    }

    p {
        transition: all .2s ease;
        margin-bottom: 30px;
        opacity: .3;
    }

    div.linkA {
        cursor: pointer;
        text-decoration: none;
        color: white;
        font-weight: bold;
        background-color: var(--col);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        max-width: 150px;
        color: white;
        padding: 5px 15px;
        align-self: end;
        transition: all .2s ease;

        &:hover {
            background-color: white;
            color: var(--col);
        }
    }
}

