@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

/*! STYLES BY t.me/fR0u1  */
/*! STYLES BY t.me/fR0u1  */
/*! STYLES BY t.me/fR0u1  */

#canvas {
    position: absolute;
    width: 100%;
    height: 100%;
}


@media (min-width: 769px) {

    * {
        font-family: "montserrat";
        --col: #e24734;
        --col2: #ce3a28;
        --bcol: #1a1a1a;
    }

    #root {
        overflow-x: hidden;
    }

    body {
        background: #1a1a1a;
        color: white;
        /* */
        margin: 0;
    }

    @keyframes pulse {

        0%,
        100% {
            box-shadow: 0 0 10px #e24734;
        }

        50% {
            box-shadow: 0 0 20px #e24734;
        }
    }

    @keyframes pulse-2 {

        0%,
        100% {
            box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
        }

        50% {
            box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
        }
    }

    /*! BTNS */
    /*! BTNS */
    /*! BTNS */

    .btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        transition: all .2s ease;
        padding: 7px 20px;
        font-size: 12px;
        border-radius: 5px;
        background-color: #e24734;
        animation: pulse 5s infinite ease-in-out;
        cursor: pointer;

        &:hover {
            transform: scale(1.1);
        }
    }

    .btn-alt {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        transition: all .2s ease;
        padding: 7px 20px;
        font-size: 12px;
        background-color: #e2483400;
        border: 1px solid var(--col);
        /* animation: pulse 5s infinite ease-in-out;  */
        cursor: pointer;

        &:hover {
            background-color: var(--col);
        }
    }

    .btn-white {
        border-radius: 3px;

        &:hover {
            color: black;
            background-color: white;
            animation: pulse-2 5s infinite ease-in-out;
        }
    }

    /*? GORDEVV.MEDIA */
    /*? GORDEVV.MEDIA */
    /*? GORDEVV.MEDIA */

    .home-page {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

    }

    ::-webkit-scrollbar {
        width: 7px;
    }

    ::-webkit-scrollbar-track {
        background-color: rgba(206, 59, 40, 0.3);
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ce3a28;
        border-radius: 15px;

        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    /* #root {
    display: flex;
    justify-content: space-between;
    position: relative;
} */


    .footer {
        /* position: absolute; */
        left: 0;
        /* margin-top: 100%; */
        bottom: 0;
        width: 100vw;
        /* background: black; */
        min-height: 100px;
        margin: 0;
    }

    .footer-content {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        .ff-info {
            font-size: 12px;
            font-weight: 200;

            .ffi-1,
            .ffi-2,
            .ffi-3 {
                opacity: .3;
            }

            .ffi-1,
            .ffi-2 {
                line-height: 50%;
                text-transform: uppercase;

            }

            .ffi-link {
                color: white;
                opacity: 100%;
                font-weight: 400;
                position: relative;
                width: 100%;
                cursor: pointer;
                transition: all .2s ease;

                &::before {
                    content: '';
                    position: absolute;
                    bottom: -20%;
                    left: 0;
                    height: 1px;
                    width: 89%;
                    background-color: var(--col);
                }

                &:hover {
                    color: var(--col);
                }
            }
        }
    }

    .footer-img {
        width: 150px;
    }

    .crs-soc-ico {
        fill: var(--col);
        width: 20px;
        height: 20px;
        margin-right: 10px;
        transition: all .2s ease;
        cursor: pointer;

        &:hover {
            fill: white;
            transform: scale(1.1);
        }
    }

    .f-social {
        p {
            font-size: 12px;
            font-weight: 200;

        }

        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        margin-left: 35px;

        .soc-icons {
            display: flex;
            margin-left: 15px;
        }
    }

    .f-contacts {
        cursor: pointer;
    }

    .fc-menuitem {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        height: 20px;
        transition: all .2s ease;
        font-weight: 400;
        cursor: pointer;

        .fc-img {
            width: 40px;
        }

        label {
            cursor: pointer;
            font-size: 12px;

        }



        &:hover {
            font-weight: 400;
            color: var(--col);
        }
    }

    .inactive-btn {
        cursor: not-allowed;
        opacity: .5;

        &:hover {
            transform: scale(1);

        }
    }

    .fade-in {
        opacity: 0;
        transform: translateY(100px);
        transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    }

    .fade-in.show {
        opacity: 1;
        transform: translateY(0);
    }

    .marquee {

        position: absolute;
        left: 0;
        /* transform: translateX(-50%); */
        overflow: hidden;
        white-space: nowrap;
        display: flex;
        align-items: center;
        width: 100%;
    }

    .marquee-content {
        display: flex;
        animation: marquee-r-l 20s linear infinite;
    }


    .marquee-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        gap: 40px;

        padding: 0 20px;
        flex: 0 0 auto;
    }

    .mi-slider-comp {
        display: flex;
    }

    .mi-slider-comp {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px 30px;
        border: 1px solid var(--col);
        border-radius: 200px;
        background-color: #e2483400;
        transition: all .2s ease;
        cursor: pointer;

        &:hover {
            background-color: var(--col);
        }
    }

    .mi-separator {
        width: 100px;
        height: 2px;
        background-color: var(--col);

    }

    @keyframes marquee-r-l {
        0% {
            transform: translateX(0%);
        }

        100% {
            transform: translateX(-50%);
        }
    }

    @keyframes marquee-l-r {
        0% {
            transform: translateX(0%);
        }

        100% {
            transform: translateX(50%);
        }
    }

}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

/*! STYLES BY t.me/fR0u1  */
/*! STYLES BY t.me/fR0u1  */
/*! STYLES BY t.me/fR0u1  */



@media (max-width: 768px) {

    #root {
        overflow-x: hidden;
    }

    * {
        font-family: "montserrat";
        --col: #e24734;
        --col2: #ce3a28;
        --bcol: #1a1a1a;
    }



    body {
        background: #1a1a1a;
        color: white;
        margin: 0;

    }


    @keyframes pulse {

        0%,
        100% {
            box-shadow: 0 0 10px #e24734;
        }

        50% {
            box-shadow: 0 0 20px #e24734;
        }
    }

    @keyframes pulse-2 {

        0%,
        100% {
            box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
        }

        50% {
            box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
        }
    }

    /*! BTNS */
    /*! BTNS */
    /*! BTNS */

    .btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        transition: all .2s ease;
        padding: 7px 20px;
        font-size: 12px;
        border-radius: 5px;
        background-color: #e24734;
        animation: pulse 5s infinite ease-in-out;
        cursor: pointer;

        &:hover {
            transform: scale(1.1);
        }
    }

    .btn-alt {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        transition: all .2s ease;
        padding: 7px 20px;
        font-size: 12px;
        background-color: #e2483400;
        border: 1px solid var(--col);
        /* animation: pulse 5s infinite ease-in-out;  */
        cursor: pointer;

        &:hover {
            background-color: var(--col);
        }
    }

    .btn-white {
        border-radius: 3px;

        &:hover {
            color: black;
            background-color: white;
            animation: pulse-2 5s infinite ease-in-out;
        }
    }

    /*? GORDEVV.MEDIA */
    /*? GORDEVV.MEDIA */
    /*? GORDEVV.MEDIA */

    .home-page {
        max-width: 768px;
        box-sizing: border-box;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        padding: 10px 20px;

    }

    ::-webkit-scrollbar {
        width: 0px;
    }

    ::-webkit-scrollbar-track {
        background-color: rgba(206, 59, 40, 0);
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ce3a28;
        border-radius: 15px;

        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    }

    /* #root {
    display: flex;
    justify-content: space-between;
    position: relative;
} */


    .footer {
        /* position: absolute; */
        left: 0;
        /* margin-top: 100%; */
        bottom: 0;
        width: 100vw;
        /* background: black; */
        min-height: 100px;
        margin: 0;
    }

    .footer-content {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        .ff-info {
            font-size: 12px;
            font-weight: 200;

            .ffi-1,
            .ffi-2,
            .ffi-3 {
                opacity: .3;
            }

            .ffi-1,
            .ffi-2 {
                line-height: 50%;
                text-transform: uppercase;

            }

            .ffi-link {
                color: white;
                opacity: 100%;
                font-weight: 400;
                position: relative;
                width: 100%;
                cursor: pointer;
                transition: all .2s ease;

                &::before {
                    content: '';
                    position: absolute;
                    bottom: -20%;
                    left: 0;
                    height: 1px;
                    width: 89%;
                    background-color: var(--col);
                }

                &:hover {
                    color: var(--col);
                }
            }
        }
    }

    .footer-img {
        display: none;
        /* width: 150px; */
    }

    .crs-soc-ico {
        fill: var(--col);
        width: 20px;
        height: 20px;
        margin-right: 10px;
        transition: all .2s ease;
        cursor: pointer;

        &:hover {
            fill: white;
            transform: scale(1.1);
        }
    }

    .f-social {
        p {
            font-size: 12px;
            font-weight: 200;

        }

        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        margin-left: 35px;

        .soc-icons {
            display: flex;
            margin-left: 15px;
        }
    }

    .f-contacts {
        cursor: pointer;
    }

    .fc-menuitem {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        height: 20px;
        transition: all .2s ease;
        font-weight: 400;
        cursor: pointer;

        .fc-img {
            width: 40px;
        }

        label {
            cursor: pointer;
            font-size: 12px;

        }



        &:hover {
            font-weight: 400;
            color: var(--col);
        }
    }

    .inactive-btn {
        cursor: not-allowed;
        opacity: .5;

        &:hover {
            transform: scale(1);

        }
    }

    /* .fade-in {
        opacity: 0;
        transform: translateY(100px);
        transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    }

    .fade-in.show {
        opacity: 1;
        transform: translateY(0);
    } */

    .marquee {
        display: none;
        position: absolute;
        left: 0;
        white-space: nowrap;
        /* display: flex; */
        align-items: center;
        width: 100%;
        z-index: -10000;
    }

    .marquee-content {
        display: flex;
        animation: marquee-r-l 20s linear infinite;
        /* width: 100%; */

    }


    .marquee-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        gap: 40px;

        padding: 0 20px;
        flex: 0 0 auto;
    }


    .mi-slider-comp {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px 30px;
        border: 1px solid var(--col);
        border-radius: 200px;
        background-color: #e2483400;
        transition: all .2s ease;
        cursor: pointer;
        max-width: 100%;

        &:hover {
            background-color: var(--col);
        }
    }

    .mi-separator {
        width: 100px;
        height: 2px;
        background-color: var(--col);

    }

    @keyframes marquee-r-l {
        0% {
            transform: translateX(0%);
        }

        100% {
            transform: translateX(-50%);
        }
    }

    @keyframes marquee-l-r {
        0% {
            transform: translateX(0%);
        }

        100% {
            transform: translateX(50%);
        }
    }


}