@media (min-width: 769px) {
    /* .cases {
        display: flex;
        flex-wrap: wrap;
        margin-top: 100px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 20px;

        .case {


        }
    } */

    .cases-block {
        h1 {
            margin: 0;
        }

        margin-top:50px;
        margin-bottom: 100px;
    }

    .cases {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        gap: 16px;
        margin-top: 30px;
    }

    .case {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        position: relative;
        height: 160px;
        width: 160px;
        border-radius: 15px;
        overflow: hidden;
        border: solid 2px var(--col);
        transition: all .2s ease;

        &:hover {
            img {
                width: 170px;
                height: 170px;
                filter: saturate(1);
            }

            &::after {
                opacity: 1;
            }

            .case-name {
                opacity: 1;
            }
        }

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            background: linear-gradient(0deg, #E24834, #E2493400);
            opacity: .5;
            transition: all .2s ease;
        }

        .case-name {
            position: absolute;
            bottom: 10px;
            left: 50%;
            z-index: 1;
            transform: translate(-50%, -20%);
            font-weight: bold;
            width: 100%;
            opacity: 0;
            transition: all .2s ease;
        }

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 160px;
            height: 160px;
            transition: all .2s ease;
            filter: saturate(1);
        }
    }

}



@media (max-width: 768px) {

    .cases-block {
        h1 {
            margin: 0;
        }

        margin-top:50px;
        margin-bottom: 100px;
    }

    .cases {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        gap: 16px;
        margin-top: 30px;
    }

    .case {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        position: relative;
        height: 160px;
        width: 160px;
        border-radius: 15px;
        overflow: hidden;
        border: solid 2px var(--col);


        &:hover {
            img {
                width: 180px;
                height: 180px;
                /* filter: saturate(1); */
            }


        }

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            background: linear-gradient(0deg, #E24834, #E2493400);
        }

        .case-name {
            position: absolute;
            bottom: 10px;
            left: 50%;
            z-index: 1;
            transform: translate(-50%, -20%);
            font-weight: bold;
            width: 100%;
        }

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 160px;
            height: 160px;
            transition: all .2s ease;
            /* filter: saturate(0); */
            transition: all .2s ease;
        }
    }
}