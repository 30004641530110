@media (min-width: 769px) {
    .consult-form-block {
        display: flex;
        flex-direction: column;
        z-index: 90;
        position: relative;
        padding-top: 50px;
        margin-top: 50px;
        margin-bottom: 250px;

        &::after {
            position: absolute;
            z-index: -90;
            content: '';
            top: 0;
            left: 50%;
            transform: translateX(-50%) rotate(7deg);
            height: 420px;
            width: 120vw;
            background-color: var(--col);
        }
    }

    .cfb-logo-label {
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 3px;
        opacity: 50%;
        color: var(--bcol);
    }

    .cfb-title {
        font-weight: 800;
        font-size: 50px;
    }

    .cfb-form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
    }

    .cfb-form-input {
        color: var(--bcol);
        outline: none;
        font-weight: 800;
        font-size: 15px;
        border: none;
        border-bottom: 1px var(--bcol) solid;
        background: transparent;
        min-width: 250px;
        transition: all .2s ease;
        position: relative;

        &::placeholder {
            transition: all .2s ease;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.7);
        }

        &:focus {
            &::placeholder {
                color: rgba(255, 255, 255, 0.4);
            }

            color: white;
            border-bottom: 1px white solid;
        }

        &:hover {
            transform: scale(1.1);
        }

    }

    .cfb-form-submit {
        display: flex;
        justify-content: center;
        padding: 15px 25px;
        font-weight: 400;
        overflow: auto;
        border: 2px solid var(--bcol);
        background-color: var(--bcol);
        color: white;
        text-transform: uppercase;
        transition: all .2s ease;
        cursor: pointer;
        border-radius: 5px;
        font-weight: 600;

        &:hover {
            border: 2px solid white;
            background-color: white;
            color: var(--bcol);
            transform: scale(1.05);
        }
    }
}

@media (max-width: 768px) {
    .consult-form-block {
        display: flex;
        flex-direction: column;
        z-index: 90;
        position: relative;
        padding-top: 50px;
        margin-top: 50px;
        margin-bottom: 250px;

        &::after {
            position: absolute;
            z-index: -90;
            content: '';
            top: 0;
            left: 50%;
            transform: translateX(-50%) rotate(7deg);
            height: 420px;
            width: 120vw;
            background-color: var(--col);
        }
    }

    .cfb-logo-label {
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 3px;
        opacity: 50%;
        color: var(--bcol);
    }

    .cfb-title {
        font-weight: 800;
        font-size: 35px;
    }

    .cfb-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
    }

    .cfb-form-input {
        color: var(--bcol);
        outline: none;
        font-weight: 800;
        font-size: 15px;
        border: none;
        border-bottom: 1px var(--bcol) solid;
        background: transparent;
        min-width: 250px;
        transition: all .2s ease;
        position: relative;

        &::placeholder {
            transition: all .2s ease;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.7);
        }

        &:focus {
            &::placeholder {
                color: rgba(255, 255, 255, 0.4);
            }

            color: white;
            border-bottom: 1px white solid;
        }

        &:hover {
            transform: scale(1.1);
        }

    }

    .cfb-form-submit {
        display: flex;
        justify-content: center;
        padding: 15px 25px;
        font-weight: 400;
        border: 2px solid var(--bcol);
        background-color: var(--bcol);
        color: white;
        text-transform: uppercase;
        transition: all .2s ease;
        cursor: pointer;
        border-radius: 5px;
        font-weight: 600;

        &:hover {
            border: 2px solid white;
            background-color: white;
            color: var(--bcol);
            transform: scale(1.05);
        }
    }
}