@media (min-width: 769px) {



    .contacts {
        display: flex;
        flex-direction: row;
        margin-top: 200px;
        margin-bottom: 300px;

        .c-left {
            min-width: 600px;
            position: relative;

            .ceo-photo {
                position: absolute;
                bottom: -90%;
                right: -50%;
                z-index: -9999;
                /* max-width: 600px; */
            }
        }

        .c-right {
            .cr-pre-title {
                text-transform: uppercase;
                color: var(--col);
                font-weight: 800;
                font-size: 12px;
            }

            .cr-title {
                margin: 20px 0;
                font-size: 50px;
                font-weight: 800;
                text-transform: uppercase;
                line-height: 100%;
            }
        }
    }

    .crs-soc-ico {
        fill: var(--col);
        max-width: 30px;
        max-height: 30px;
        margin-right: 10px;
        transition: all .2s ease;
        cursor: pointer;

        &:hover {
            fill: white;
            transform: scale(1.1);
        }
    }
}

@media (max-width: 768px) {



    .contacts {
        display: flex;
        flex-direction: column;
        margin-top: 200px;
        margin-bottom: 200px;
        .c-left {
            position: relative;
            
            .ceo-photo {
                position: absolute;
                left: 50%;
                transform: translate(-34%);
                z-index: -9999;
            }
        }

        .c-right {
            .cr-pre-title {
                text-transform: uppercase;
                color: var(--col);
                font-weight: 800;
                font-size: 12px;
            }

            .cr-title {
                margin: 20px 0;
                font-size: 50px;
                font-weight: 800;
                text-transform: uppercase;
                line-height: 100%;
            }
        }
    }

    .crs-soc-ico {
        fill: var(--col);
        max-width: 30px;
        max-height: 30px;
        margin-right: 10px;
        transition: all .2s ease;
        cursor: pointer;

        &:hover {
            fill: white;
            transform: scale(1.1);
        }
    }
}