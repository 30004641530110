#payform-tbank {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    gap: 5px;

    .inpGroup {
        display: flex;
        flex-direction: column;
    }

    .separator {
        min-height: 20px;
    }

    label {
        font-weight: 400;
        opacity: .5;
        font-size: 12px;
    }

    h2 {
        display: flex;
        font-size: 22px;
        
        margin: 5px auto;
    }

    & input {
        display: flex;
        border: none;
        border-radius: 4px;
        min-height: 25px;
        min-width: 300px;
        transition: all .2s ease;
        padding: 2px 5px;
        outline: none;
        opacity: .7;

        &:focus-visible {
            opacity: 1;
        }
    }

    input[type="submit"] {
        opacity: 1;
        margin-top: 10px;
        min-height: 30px;
        box-shadow: 0 0 15px #ffffff75;
    }

    input[type="submit"]:hover {
        cursor: pointer;
        color: white;
        background-color: #e24734;
        box-shadow: 0 0 15px #e2483473;

    }
}