@media (min-width: 769px) {

    .feedback-form {
        display: flex;
        flex-direction: column;
        /* gap: 10px */
    }

    .fb-part {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .fb-label {
        font-weight: 700;
        color: var(--col);
        font-size: 15px;
        margin-top: 30px;
    }

    .fb-inp {
        outline: none;
        font-weight: 700;
        font-size: 12px;
        border: none;
        color: rgba(255, 255, 255, 0.3);
        border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
        background: transparent;
        min-width: 250px;
        transition: all .2s ease;
        position: relative;
        margin-top: 10px;
        padding-left: 5px;
        padding-bottom: 3px;

        &::placeholder {
            font-weight: 500;
            transition: all .2s ease;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.3);
        }

        &:focus {
            &::placeholder {
                color: rgba(255, 255, 255, 0.4);
            }

            color: white;
            border-bottom: 1px white solid;
        }

        &:hover {
            &::placeholder {
                color: rgba(255, 255, 255, 0.7);
            }

            border-bottom: 1px rgba(255, 255, 255, 0.7) solid;
        }

    }

    .fb-btn {
        align-self: center;
        width: 50%;
        margin-top: 20px;
    }

    .fbf-title {
        font-size: 45px;
        width: 120px;
        word-break: normal;
        line-height: 100%;
        text-transform: uppercase;
        font-weight: 800;
        margin: 20px 0px;
    }

    .fbf-description {
        margin: 0;
        line-height: 100%;
        font-weight: 500;
        opacity: 50%;
        font-size: 15px;
    }
}


@media (max-width: 768px) {

    .feedback-form {
        display: flex;
        flex-direction: column;
        /* gap: 10px */
    }

    .fb-part {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .fb-label {
        font-weight: 700;
        color: var(--col);
        font-size: 15px;
        margin-top: 30px;
    }

    .fb-inp {
        outline: none;
        font-weight: 700;
        font-size: 12px;
        border: none;
        color: rgba(255, 255, 255, 0.3);
        border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
        background: transparent;
        min-width: 250px;
        transition: all .2s ease;
        position: relative;
        margin-top: 10px;
        padding-left: 5px;
        padding-bottom: 3px;

        &::placeholder {
            font-weight: 500;
            transition: all .2s ease;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.3);
        }

        &:focus {
            &::placeholder {
                color: rgba(255, 255, 255, 0.4);
            }

            color: white;
            border-bottom: 1px white solid;
        }

        &:hover {
            &::placeholder {
                color: rgba(255, 255, 255, 0.7);
            }

            border-bottom: 1px rgba(255, 255, 255, 0.7) solid;
        }

    }

    .fb-btn {
        align-self: center;
        width: 50%;
        margin-top: 20px;
    }

    .fbf-title {
        font-size: 35px;
        width: 120px;
        word-break: normal;
        line-height: 100%;
        text-transform: uppercase;
        font-weight: 800;
        margin: 20px 0px;
    }

    .fbf-description {
        margin: 0;
        line-height: 100%;
        font-weight: 500;
        opacity: 50%;
        font-size: 15px;
    }
}

